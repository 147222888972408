import { post } from '@/utils/request'

//获取目录
export function externalCourseList(data) {
    return post('/edu/external_course/externalCourseList', data)
}
//添加教务类型
export function addExternalCourse(data) {
    return post('/edu/external_course/addExternalCourse', data)
}
//教务类型回显
export function getInfo(data) {
    return post('/edu/external_course/externalCourseInfo', data)
}

//教务类型编辑
export function editExternalCourse(data) {
    return post('/edu/external_course/editExternalCourse', data)
}
//教务类型删除
export function delExternalCourse(data) {
    return post('/edu/external_course/delExternalCourse', data)
}



