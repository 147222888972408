import { render, staticRenderFns } from "./OtherCourses.vue?vue&type=template&id=f10342aa&scoped=true&"
import script from "./OtherCourses.vue?vue&type=script&lang=js&"
export * from "./OtherCourses.vue?vue&type=script&lang=js&"
import style0 from "./OtherCourses.vue?vue&type=style&index=0&id=f10342aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f10342aa",
  null
  
)

export default component.exports