<template>
  <div>
    <el-card>
      <all-log :dialogLog="dialogLog" />

      <div class="menu-box">
        <h2>外来课程管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加外来课程</el-button>
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="course_name" label="外来课程名称">
            </el-table-column>

            <el-table-column prop="status" width="500" label="课程状态">
              <template slot-scope="scope">
                <!-- <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag> -->
                <el-tag v-if="scope.row.status === 6">已取消</el-tag>
                <el-steps
                  v-else
                  :space="150"
                  :active="scope.row.status - 1"
                  finish-status="success"
                >
                  <el-step title="审核中"></el-step>
                  <el-step title="试讲中"></el-step>
                  <el-step title="评估中"></el-step>
                  <el-step title="签署协议中"></el-step>
                  <el-step title="已完成"></el-step>
                </el-steps>
              </template>
            </el-table-column>
            <el-table-column prop="file_pdf" label="pdf课件">
              <template slot-scope="scope">
                <a target="_blank" :href="scope.row.file_pdf" class="file-img">
                  <img width="50" src="../../../assets/pdf.png" />
                </a>
              </template>
            </el-table-column>
            <el-table-column prop="file_pdf" label="ppt课件">
              <template slot-scope="scope">
                <a target="_blank" :href="scope.row.file_ppt" class="file-img">
                  <img width="50" src="../../../assets/ppt.png" />
                </a>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status === 1"
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  v-if="scope.row.status === 1"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="name"
          label="外来课程名称"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.name"
            placeholder="请填写外来课程名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="pdf"
          :label-width="formLabelWidth"
          label="上传pdf课件"
        >
          <el-button
            type="primary"
            @click="uploadPDF()"
            size="mini"
            class="form-btn"
            >点击上传</el-button
          >
          <img
            v-if="pdfname !== '' && editPDF === false"
            style="margin-bottom: -10px;"
            width="30"
            src="../../../assets/pdf.png"
          />
          <span style="margin-left:5px;font-size: 12px;color: black;">{{
            pdfname
          }}</span>
          <a
            target="_blank"
            v-if="addOrEdit === 2"
            :href="form.file_pdf"
            class="file-img"
          >
            <img
              style="margin-bottom: -10px;"
              width="30"
              src="../../../assets/pdf.png"
            />
          </a>
        </el-form-item>
        <el-form-item
          prop="ppt"
          :label-width="formLabelWidth"
          label="上传ppt课件"
        >
          <!-- <el-upload
            :headers="token"
            accept=".ppt,pptx"
            :limit="1"
            :on-exceed="exceed"
            :action="imgUrl"
            :file-list="filelistPpt"
            :on-change="uploadPPT"
          >
            <el-button size="small" type="primary">上传</el-button>
          </el-upload> -->

          <el-button
            type="primary"
            @click="uploadPPT()"
            size="mini"
            class="form-btn"
            >点击上传</el-button
          >
          <img
            v-if="pptname !== '' && editPPT === false"
            style="margin-bottom: -10px;"
            width="30"
            src="../../../assets/ppt.png"
          />
          <span style="margin-left:5px;font-size: 12px;color: black;">{{
            pptname
          }}</span>
          <a
            target="_blank"
            v-if="addOrEdit === 2"
            :href="form.file_ppt"
            class="file-img"
          >
            <img
              style="margin-bottom: -10px;"
              width="30"
              src="../../../assets/ppt.png"
            />
          </a>
        </el-form-item>
        <!-- <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item> -->
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  externalCourseList,
  addExternalCourse,
  getInfo,
  editExternalCourse,
  delExternalCourse,
} from '@/api/edu/othercourses.js'
// import lodash from 'lodash'

export default {
  data() {
    var validatePPT = (rule, value, callback) => {
      if (
        !this.formData.get('file_ppt') &&
        !this.formData.get('file_ppt_url')
      ) {
        callback(new Error('ppt课件不能为空！'))
      } else {
        callback()
      }
    }
    var validatePDF = (rule, value, callback) => {
      if (
        !this.formData.get('file_pdf') &&
        !this.formData.get('file_ppt_url')
      ) {
        callback(new Error('pdf课件不能为空！'))
      } else {
        callback()
      }
    }
    return {
      dialogLog: {
        state: false,
      },
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      pdfname: '',
      pptname: '',
      filelistPdf: [],
      filelistPpt: [],
      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },
      editPPT: false,
      editPDF: false,
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        name: {
          required: true,
          message: '请填写课程名称',
          trigger: 'blur',
        },
        ppt: {
          required: true,
          validator: validatePPT,
        },
        pdf: {
          required: true,
          validator: validatePDF,
        },
      },
      title: '添加外来课程',
      addOrEdit: 1,
      formLabelWidth: '160px',

      form: {
        name: '',
        file_pdf: '',
        file_ppt: '',
      },
      tableData: [],
      formData: new FormData(),
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  computed: {
    imgUrl() {
      return this.$store.state.imgUrlUpload
    },
  },
  methods: {
    exceed() {
      this.$message({
        message: '只能上传一个文件',
        type: 'warning',
      })
    },
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true

      const { data: res } = await externalCourseList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加外来课程'
      this.addOrEdit = 1
      this.editPDF = false
      this.editPPT = false
      this.isEdit = false
      this.form = {
        name: '',
        file_pdf: '',
        file_ppt: '',
      }
      this.pdfname = ''
      this.pptname = ''
      this.formData = new FormData()
      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      //  console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      this.dialogVisible = true
      this.title = '编辑外来课程'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        course_id: row.id,
      })
      console.log(res.data)
      this.form.status = res.data.status
      this.form.name = res.data.course_name
      this.form.course_id = row.id
      this.form.file_pdf = res.data.file_pdf
      this.form.file_ppt = res.data.file_ppt
      this.formData.set('file_pdf_url', this.form.file_pdf)
      this.formData.set('file_ppt_url', this.form.file_ppt)
    },

    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delExternalCourse({
            course_id: row.id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            this.formData.append('name', this.form.name)
            const { data: res } = await addExternalCourse(this.formData)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
          } else {
            // 编辑

            if (this.isEdit === true) {
              this.formData.append('course_id', this.form.course_id)
              this.formData.append('name', this.form.name)
              if (!this.formData.get('file_pdf')) {
                // 没改
                this.formData.set('file_pdf_url', this.form.file_pdf)
                this.formData.set('file_pdf', '')
              } else {
                //改了
                this.formData.set('file_pdf_url', '')
              }
              if (!this.formData.get('file_ppt')) {
                // 没改
                this.formData.set('file_ppt_url', this.form.file_ppt)
                this.formData.set('file_ppt', '')
              } else {
                //改了
                this.formData.set('file_ppt_url', '')
              }
              console.log(this.formData)
              const { data: res } = await editExternalCourse(this.formData)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
          }
        }
      })
    },
    uploadPDF(file) {
      if (this.addOrEdit === 2) {
        this.editPDF = true
        this.isEdit = true
      }
      const _this = this

      const fileType = ['pdf']
      const inputFile = document.createElement('input')
      inputFile.type = 'file'
      inputFile.style.display = 'none'
      document.body.appendChild(inputFile)
      inputFile.click()

      inputFile.addEventListener('change', function() {
        const file = inputFile.files[0]
        console.log(file)
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        if (!fileType.includes(testmsg)) {
          _this.$message.warning('上传的文件格式只能是pdf')
          document.body.removeChild(inputFile)
          return false
        }

        _this.formData.set('file_pdf', file)
        console.log(_this.formData.get('file_pdf'))
        _this.pdfname = _this.formData.get('file_pdf').name
      })
    },
    uploadPPT(file) {
      if (this.addOrEdit === 2) {
        this.editPPT = true
        this.isEdit = true
      }
      const _this = this
      //   const fileType = [
      //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //     "application/vnd.ms-excel"
      //   ];
      const fileType = ['ppt', 'pptx']
      const inputFile = document.createElement('input')
      inputFile.type = 'file'
      inputFile.style.display = 'none'
      document.body.appendChild(inputFile)
      inputFile.click()

      inputFile.addEventListener('change', function() {
        const file = inputFile.files[0]
        console.log(file)
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        if (!fileType.includes(testmsg)) {
          _this.$message.warning('上传的文件格式只能是ppt')
          document.body.removeChild(inputFile)
          return false
        }
        _this.formData.set('file_ppt', file)
        _this.pptname = _this.formData.get('file_ppt').name
      })
      // this.isEdit = true

      //  this.filelistPdf = []
      // this.filelistPdf.push(file.raw)
      // this.form.file_ppt = file.raw
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
</style>
